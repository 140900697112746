import * as dayjs from 'dayjs';

export interface IGlStatement {
  id?: number;
  appId?: string;
  glYear?: number;
  glAccountNumber?: string;
  name?: string;
  nameTranslations?: Map<string, string> | null;
  openingDebit?: number | null;
  openingCredit?: number | null;
  debit?: number | null;
  credit?: number | null;
  openingBalance?: number | null;
  turnoverBalance?: number | null;
  debitSum?: number | null;
  creditSum?: number | null;
  balance?: number | null;
  aggregatedFlag?: boolean | null;
  inUseFlag?: boolean | null;
  createdBy?: string | null;
  createdDate?: dayjs.Dayjs | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: dayjs.Dayjs | null;
  prevLastModifiedDate?: string | null;
}

export class GlStatement implements IGlStatement {
  constructor(
    public id?: number,
    public appId?: string,
    public glYear?: number,
    public glAccountNumber?: string,
    public name?: string,
    public nameTranslations?: Map<string, string> | null,
    public openingDebit?: number | null,
    public openingCredit?: number | null,
    public debit?: number | null,
    public credit?: number | null,
    public openingBalance?: number | null,
    public turnoverBalance?: number | null,
    public debitSum?: number | null,
    public creditSum?: number | null,
    public balance?: number | null,
    public aggregatedFlag?: boolean | null,
    public inUseFlag?: boolean | null,
    public createdBy?: string | null,
    public createdDate?: dayjs.Dayjs | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: dayjs.Dayjs | null,
    public prevLastModifiedDate?: string | null
  ) {
    this.aggregatedFlag = this.aggregatedFlag ?? false;
  }
}

export function getGlStatementIdentifier(glStatement: IGlStatement): number | undefined {
  return glStatement.id;
}
